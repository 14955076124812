body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  /* font-family: "lucida grande", "lucida sans unicode", lucida, helvetica, "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif !important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

#root {
  overflow: hidden;
  height: inherit;
}

.children-content {
  height: calc(100vh - 120px);
  overflow-y: auto;
  padding: 0;
}

.copyright {
  padding: 1;
  font-size: small;
}

.memo-title {
  width: 100%;
  padding: 1;
  font-size: large;
  font-weight: bolder;
  text-align: center;
}

.memo-subtitle {
  width: 100%;
  padding: 1;
  font-size: small;
  text-align: left;
  color: gray;
}

.chat-message {
  padding: 5px;
  min-width: 20px;
  max-width: 700px;
  text-wrap: 'wrap';
  word-break: 'break-word';
  overflow-wrap: break-word;
}

.chat-local {
  background-color: lightblue;
  border-radius: 0.5em 0px 0.5em 0.5em;
}

.chat-other {
  background-color: rgba(12, 35, 117, 0.08);
  border-radius: 0px 0.5em 0.5em 0.5em;
}
